import { useField } from "formik";

export default function FormTextField(props) {
    const [field, meta] = useField(props);

    return (
        <>
            <div className="mb-2">
                <label className="text-sm text-gray-600">{props.label}</label>
                <label className="text-red-500 text-sm font-semibold px-2 text-left">{meta.error ? meta.error : ""}</label>
            </div>
            <input
                onChange={e => {
                    props.onChange(e);
                }}
                className={
                    `w-100 px-3 py-2 bg-white border border-slate-300 rounded-3xl 
                     focus:outline-none focus:border-black-500 focus:ring-1 focus:ring-black-500
                     invalid:border-red-500 invalid:text-red-600
                     focus:invalid:border-red-500 focus:invalid:ring-red-500`
                }
                {...field} {...props} />
        </>
    );
};
