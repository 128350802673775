import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { UserService, UserModel } from "../../services/UserService"

export function ProfileAvatar() {
    const [user, setUser] = useState<UserModel | null>(null);
    const service = useMemo(() => { return new UserService() }, []);


    if (user === null) {
        service.GetUser().then((u) => {
            setUser(u);
        });
    }

    useEffect(() => {
        service.AddUserUpdateHandler('ProfileAvatar', (u) => setUser(u));
        return () => { service.RemoveUserUpdateHandler('ProfileAvatar') };
    }, [service]);


    return (
        <Link to='/profile'>
            <div className="self-center">
                <div className="flex flex-row gap-2">
                    <div
                        style = {{ width:'45px', height:'45px'}}
                        dangerouslySetInnerHTML={{ __html: user?.displayIconInnerHTML ?? "" }}
                    ></div>
                    <div className="grow text-sm font-medium self-center">{user === null ? "Loading" : user.displayName}</div>
                </div>
            </div>
        </Link>
    );
}