import { Outlet } from "react-router-dom"
import React from "react";
import { Stack } from "react-bootstrap";
import CurrentPageIndicator from './assets/indicator_cur.svg';
import PastPageIndicator from './assets/indicator_past.svg';
import FuturePageIndicator from './assets/indicator_future.svg';

export function OnboardingProgressView() {

    const [count, setCount] = React.useState(0);

    return (
        <div class="bg-slate-200 h-screen grid grid-rows-6 grid-cols-4">
            <div class="row-start-1 col-start-2 col-span-2 place-self-center">
                <Stack direction="horizontal" gap={3}>
                    <p class="font-semibold text-gray-500 h-2">Step {Math.min(3, count + 1)} of 3</p>

                    <Stack direction="horizontal">
                        {[...Array(3)].map((x, i) =>
                            <img className="h-6 p-1"
                                src={(i === count) ? CurrentPageIndicator : ((i < count ) ? PastPageIndicator : FuturePageIndicator)}
                                alt={count}
                            />
                        )}
                    </Stack>
                </Stack>
            </div>

            <div class="row-start-2 row-span-4 col-start-2 col-span-2">
                <Outlet context={[count, setCount]} />
            </div>
        </div>
    )
}