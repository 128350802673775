import { Avatar, Divider, Stack } from "@mui/material"
import { Link, Outlet } from "react-router-dom"
import React, { useState } from "react"
import Dropdown from "./Dropdown"
import { motion } from "framer-motion"
import { AiFillApple } from "react-icons/ai";
import Helmet from "react-helmet"
import { UserService, UserModel } from "../../services/UserService"
import { ProfileAvatar } from "../profile/ProfileAvatar";
import HomeRow from "./HomeRow"

export function DashboardRoot() {
    const [user, setUser] = useState(null);

    const service = new UserService();
    if (user === null) {
        service.GetUser().then((u) => setUser(u));
    }

    return (
        <>
            <Helmet bodyAttributes={{ style: 'background-color : #F7F7F9' }} />

            <div class="h-full grid grid-cols-4">
                <HomeRow/>

                <div class="col-start-0 col-span-4 py-10 z-0">
                    <Outlet />
                </div>
            </div >
        </>
    )
}