import { Card, CardContent, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import StepProgressIndicator from "./StepProgressIndicator.js";
import CardHeader from "./CardHeader.js";
import { HasGlobalAuthToken } from "../../services/UserService";

const FullHeightCard = (props) => (
    <Card
        variant="outlined"
        sx={{
            borderRadius: 3,
        }}
        style={{ height: '100%' }}
    >
        {props.children}
    </Card >
);

export default function Home() {
    if (!HasGlobalAuthToken()) {
        window.location.href = '/login';
        console.log('does not have current auth token... should exit');
    } else {
        console.log('has a global auth token..');
    }
    
    const activeStepIdx = 1
    const steps = [
        "Branch cut",
        "Build release candidate",
        "Edit metadata",
        "Submit to App Store"
    ]

    return (
        <Grid
            container
            alignItems="stretch"
            spacing={2}
            px={30}>
            <Grid item xs={4}>
                <FullHeightCard >
                    <div class="flex flex-col p-3 h-full gap-1">
                        <Link>
                            <CardHeader text="LIVE" color="text-red-500" />
                        </Link>

                        <div class="text-md font-medium text-slate-500 grow top-5">
                            Released 5 days ago
                        </div>
                        <div class="text-4xl font-bold grow-0">9.43</div>
                    </div>
                </FullHeightCard>
            </Grid>

            <Grid item xs={8}>
                <FullHeightCard>
                    <CardContent class="p-3">
                        <div class="flex flex-col gap-4">
                            <Link>
                                <CardHeader text="UPCOMING" color="text-purple-500" />
                            </Link>
                            <StepProgressIndicator steps={steps} />
                        </div>
                    </CardContent>
                </FullHeightCard>
            </Grid>

            <Grid item xs={12}>
                <FullHeightCard>
                    <CardContent class="p-3">
                        <Link>
                            <CardHeader text="CANDIDATES" color="text-orange-500" />
                        </Link>
                    </CardContent>
                </FullHeightCard>
            </Grid>
        </Grid >
    );
}