import React, { useState } from "react";
import { UserService, UserModel } from "../../services/UserService";


export default function UserLoginBootstrap() {
	const [user, setUser] = useState<UserModel | null>(null);
	const [isMakingRequest, setIsMakingRequest] = useState(false);

	const service = new UserService();
	const currentWindowLocationURL = new URL(window.location.href);
	const params = currentWindowLocationURL.searchParams;

	if (params.has('auth')) {
		service.SetGlobalAuthToken(params.get('auth'));
	}

	if (user === null && isMakingRequest === false) {
		setIsMakingRequest(true);
		service.GetUser()
			.then((e) => {
				setUser(e);
				setIsMakingRequest(false);
				window.location.href = `/dashboard`;
				console.log("should go to dashboard.")
			});
	}

	return (
		<div>
			<p>Loading, Please Wait...</p>
		</div>
	);
}