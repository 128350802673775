import http from "axios";
import { createAvatar } from "@dicebear/core";
import { loreleiNeutral } from "@dicebear/collection";

export declare interface UserModel {
    displayName: string;
    displayIconInnerHTML: string;
    hasAppleCredentials: boolean;
};

export function HasGlobalAuthToken(): boolean {
    return window.localStorage.getItem('HX-Authentication') !== null;
}

class User {

    displayName: string;
    displayIconInnerHTML: string;
    hasAppleCredentials: boolean;

    constructor(from: any) {
        if (from['display_name']) {
            this.displayName = from['display_name'];
        } else {
            this.displayName = from['email'];
        }

        if (from['display_icon_url'] === null) {
            const avtr = createAvatar(loreleiNeutral, {
                radius: 50,
                backgroundColor: ["b6e3f4","c0aede","d1d4f9"]
            });

            this.displayIconInnerHTML = avtr.toString();
        }

        this.hasAppleCredentials = from['_apple_credentials'] !== null;
    }
}

export declare enum UpdateNameError {
    RequestFailed
};

var g_isRequestingNewUserData = false;
var g_handlers = new Map<string,  ((newUser: UserModel) => void)>();

export class UserService {

    constructor() {
        const token = window.localStorage.getItem('HX-Authentication');
        if (token) {
            http.defaults.headers['HX-Authentication'] = token;
        }
    }

    AddUserUpdateHandler(uid: string, handler: ((newUser: UserModel) => void)) {
        g_handlers.set(uid, handler);
    }

    RemoveUserUpdateHandler(uid: string) {
        g_handlers.delete(uid);
    }

    SetGlobalAuthToken(token: string) {
        window.localStorage.setItem('HX-Authentication', token);
        http.defaults.headers['HX-Authentication'] = token;
    }

    async GetUser(arg:{hardRefresh: boolean} = {hardRefresh:false}): Promise<UserModel | null>
    {
        let userData = (() => {
            try {
                return JSON.parse(window.localStorage.getItem('HX-UserData'));
            } catch(_) {
                return null;
            } }
        )();

        if (userData === null || arg.hardRefresh) {
            console.log('User data is not cached, pulling from network...');
            const res = await http.get(`https://api.hexai.dev/user`);
            userData = res.data;
            window.localStorage.setItem('HX-UserData', JSON.stringify(userData));
            window.localStorage.setItem('HX-UserData-Timestamp', Date.now().toString());
        } else {
            console.log('Using cached user data... checking if user should be opportunistically refreshed.');
            const currentTimestamp = window.localStorage.getItem('HX-UserData-Timestamp');
            var lastUpdatedOffset = null;
            try { lastUpdatedOffset = Number(currentTimestamp); } catch (_) {}

            const currentTime = Date.now();
            const timeDelta = Math.abs(currentTime - lastUpdatedOffset);
            const shouldRefreshUserAsync = lastUpdatedOffset === null ? true : timeDelta > 5 * 60 * 1000 /* 5 minutes */;
            console.log(`User should be refreshed check result: shouldRefreshUserAsync=${shouldRefreshUserAsync} lastUpdatedOffset=${lastUpdatedOffset} currentTime=${currentTime}, timeDelta=${timeDelta}`);
            if (shouldRefreshUserAsync) {
                console.log("should async refresh... sending request now...");
                if (!g_isRequestingNewUserData) {
                    g_isRequestingNewUserData = true;
                    http.get(`https://api.hexai.dev/user`).then((res) => {
                        console.log('did get refresh message back');
                        window.localStorage.setItem('HX-UserData', JSON.stringify(res.data));
                        window.localStorage.setItem('HX-UserData-Timestamp', Date.now().toString());
                        g_isRequestingNewUserData = false;
                    });
                }
            }
        }
        
        return new User(userData);
    }

    async UpdateName(newName: string): Promise<UserModel | UpdateNameError> {
        const data = {
            "set_display_name": newName
        };
        
        const res = await http.patch("https://api.hexai.dev/user", data);

        if (res.status === 200) {
            /* sleep for 1 second, just a bit of time to make sure the daemon has time to update */
            await new Promise(r => setTimeout(r, 1000));
            const newUser = await this.GetUser({hardRefresh: true});
            g_handlers.forEach((v, _) => {
                v(newUser);
            });
            return newUser;
        } else {
            return UpdateNameError.RequestFailed;
        }
    }

    async SetAppleAPIKey(args: null | {teamID: string, issuerID: string, keyData: string}): Promise<UserModel | UpdateNameError> {
        const data = {
            "_set_apple_api_key": {
                "id": args?.teamID ?? null,
                "issuer_id": args?.issuerID ?? null,
                "data": args?.keyData ?? null
            }
        }

        const res = await http.patch("https://api.hexai.dev/user", data);
        if (res.status === 200) {
            /* sleep for 1 second, just a bit of time to make sure the daemon has time to update */
            await new Promise(r => setTimeout(r, 1000));
            const newUser = await this.GetUser({hardRefresh: true});
            g_handlers.forEach((v, _) => {
                v(newUser);
            });
            return newUser;
        } else {
            return UpdateNameError.RequestFailed;
        }
    }
}