import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

import OnboardingCTAButton from "./OnboardingCTAButton";
import PersonalSVG from './assets/personal.svg';
import TeamSVG from './assets/team.svg';
import { IoIosCheckmarkCircle, IoIosRadioButtonOff } from 'react-icons/io'

export default function NewUserType() {
    const [count, setCount] = useOutletContext();
    const incrementPage = () => setCount((c) => c + 1);

    const [userTypeValue, setUserTypeValue] = useState('');

    const userTypes = [
        { type: 'Personal use', description: 'Use hex to effortlessly launch side projects' },
        { type: 'For team', description: 'Fully automate your team’s release process' },
    ];

    const handleClick = (userType) => {
        setUserTypeValue(userType.type)
    };

    return (
        <div class="grid place-content-center grid-cols-4">
            <div class="col-start-1 col-span-4">
                <h1 class="font-bold text-3xl text-center">How do you plan on using hex?</h1>
                <p class="text-center text-gray-500">We'll streamline your setup experience accordingly.</p>
            </div>

            {userTypes.map((userType, idx) => (
                <div class={`${idx === 0 ? "col-start-1 justify-self-end pr-5" : "col-start-3 justify-self-start pl-5"} col-span-2 py-5 w-4/5`}>
                    <button className={`p-3 rounded-2xl bg-white border-4 ${userTypeValue === userType.type ? "border-blue-600" : "border-white"}`}
                        id={`user-type-${idx}`}
                        onClick={() => handleClick(userType)}>
                        <Stack direction="horizontal">
                            <div class="grow self-start">
                                <img className="h-32 pb-2" src={idx === 0 ? PersonalSVG : TeamSVG} alt={userType.type} />
                            </div>
                            <div class="self-start">
                                {userTypeValue === userType.type ? <IoIosCheckmarkCircle size={30} color='blue' /> : <IoIosRadioButtonOff size={30} color='gray' />}
                            </div>
                        </Stack>
                        <p class="mt-3 text-left text-black font-bold text-xl">{userType.type}</p>
                        <p class="my-2 text-left text-gray-500 font-medium">{userType.description}</p>
                    </button>
                </div>
            ))}

            <div class="col-start-2 col-span-2">
                <OnboardingCTAButton
                    disabled={userTypeValue === ''}
                    destination="/onboarding/repo-url"
                    onClick={incrementPage}
                />
            </div>
        </div>
    );
}