import { Link } from "react-router-dom";

export default function OnboardingCTAButton(props) {
    const { destination, disabled, onClick, text } = props

    return (
        <div class="flex place-content-center">
            <Link class="center flex-1 max-w-sm" to={destination}>
                <button
                    disabled={disabled}
                    onClick={onClick}
                    class={`rounded-full text-white ${disabled ? "bg-gray-400" : "bg-blue-600"} px-20 w-full py-2.5`}>
                    {text ? text : "Continue"}
                </button>
            </Link>
        </div>
    );
}