import OnboardingCTAButton from "./OnboardingCTAButton";
import { Stack } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import { Toaster, toast } from "react-hot-toast";

export default function DeployKey() {
    const sshPublicKey = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQC7FdNWNPf2psdBMSm2zXGhibsytBPJjuWPflIWXxfbXdPN3KaUlnuYGJuPvUEg7PeY98/eUYneziIzulci90dB2wX7OB5Bt6Ql+DAp9dxt09UCPfJwZRyUOd3ml1/+HltBPL9dg/vvwzBKLy1bez/1mHyh9D6iPSKhUA6xK/o6sEgPjaQp4TcUhK1hUcC8fJCdBvn3RSCjwkp2fa0WhCi17UgXOLErVSlCLBO8gCHH3XDJjvVOBfEdSmK8ZxiVsptSgz7qmSum9cFOAn+oRYn37FuHkoSChSrhXdC17N8GZcB9B9bHTjRnOJd3rR4maKsD5LbqHclaA+HHbckYMZP1zXTTqEyFnFdL7g+JANJMOveQ5UyIg1Hfp7OYzZbmlfyrnIdT7WKus8wGG275JlMq601HUzIbJ9C6cOjrLjVwexsgMWSxfi1KnK0lNojW98pDkNFfg/7eOXoCY+ikgKZj0SV0kPlsZhTpQpPwIDhdRg+BwayxudW0lY8tlq2evxCfoo@hex.com'
    const prodStripe = 'https://buy.stripe.com/fZecOpg3d8oGciA6or'
    const testStripe = 'https://buy.stripe.com/test_00g28H2sm8yv7qE146'

    const launchStripe = () => {
        window.location.href = testStripe
    }

    const copySSHKey = () => {
        navigator.clipboard.writeText(sshPublicKey);
        toast.success('Key copied')
    }

    return (
        <div class="grid-cols-4">
            <div>
                <Toaster position="bottom-center" reverseOrder={false} />
            </div>

            <div class="col-start-2 col-span-2">
                <h1 class="font-bold text-3xl lg text-center">Give Hex read access to your repo</h1>
                <p class="text-center text-gray-500">Add the below deploy key so Hex CD can build your app.</p>

                <div class="py-4 relative">
                    <div class="p-4 bg-[#222222] text-white rounded-xl">
                        <code class="break-all text-white">
                            {sshPublicKey}
                        </code>
                    </div>

                    <button
                        class="absolute top-10 right-6 text-black p-1.5 px-2.5 bg-white font-semibold rounded"
                        onClick={copySSHKey}
                    >
                        <Stack direction="horizontal" gap={2}>
                            <FiCopy />
                            Copy key
                        </Stack>
                    </button>
                </div>

                <OnboardingCTAButton onClick={launchStripe} />
            </div>
        </div>
    );
}