import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";

import FormTextField from "./FormTextField";
import OnboardingCTAButton from "./OnboardingCTAButton";

export default function RepoURL() {
    const [count, setCount] = useOutletContext();
    const incrementPage = () => setCount((c) => c + 1);

    return (
        <div class="flex grow place-content-center">
            <div class="max-w-lg grow">
                <h1 class="font-bold text-3xl text-center">Where does your app live?</h1>
                <p class="text-center text-gray-500">Hex will try to clone and build the app.</p>
                <div class="my-4">
                    <Formik
                        initialValues={{
                            url: ""
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .url("Invalid URL")
                                .required("Required")
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await new Promise((r) => setTimeout(r, 500));
                            setSubmitting(false);
                        }}
                    >
                        <Form>
                            <FormTextField
                                name="email"
                                type="url"
                                placeholder="https://github.com/your-company/repo"
                            />
                        </Form>
                    </Formik>
                </div>

                <OnboardingCTAButton 
                onClick={incrementPage}
                destination="/onboarding/deploy-key" />
            </div>
        </div>
    );
}