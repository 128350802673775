import { Stack } from "@mui/system"
import { BsArrowRightShort } from "react-icons/bs";
import '../../App.css';

export default function CardHeader(props) {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <div class={`text-xs font-bold ${props.color} grow h-fit`}>{props.text}</div>
            <div class="text-xs font-medium text-slate-400">Details</div>
            <BsArrowRightShort class="text-slate-400"/>
        </Stack>
    )
}