import { React, useState } from "react"
import { Divider, Stack } from "@mui/material"
import { Link, Outlet } from "react-router-dom"
import Dropdown from "./Dropdown"
import { motion } from "framer-motion"
import { AiFillApple } from "react-icons/ai";
import Helmet from "react-helmet"
import { UserService, UserModel } from "../../services/UserService"
import { ProfileAvatar } from "../profile/ProfileAvatar";

export default function HomeRow() {
    const [selectedIdx, setSelectedIdx] = useState(0)
    const [xOffset, setXOffset] = useState(0)

    const tabs = [
        { name: "Home", path: "/dashboard" },
        { name: "Settings", path: "/dashboard/settings" },
        { name: "Alerts", path: "/dashboard/alert" }
    ]

    function didTapSegment(prop) {
        setSelectedIdx(prop.idx)
        setXOffset((119 * prop.idx) - 3)
    }

    return (
        <div class="px-4 sticky absolute top-5 flex col-start-0 col-span-4 content-center justify-center z-[100]">
                    <div class="flex-none self-center">
                        <Dropdown innerContent={
                            <div>Twitter</div>
                        }
                        />
                    </div>
                    <div class="static grow">
                        <div class="flex justify-center">
                            <div class="relative flex align-middle gap-3 p-2 rounded-full bg-white border border-slate-600 drop-shadow">
                                {
                                    tabs.map((tab, i) =>
                                        <Link
                                            key={tab.name}
                                            class={`px-4 py-1 text-md font-semibold rounded-full text-center z-30 w-30 hover:text-slate-700 ${i === selectedIdx ? "text-black" : "text-slate-500"}`}
                                            style={{ textDecoration: 'none' }}
                                            to={tab.path}
                                            onClick={() => didTapSegment({ idx: i })}>
                                            {tab.name}
                                        </Link>
                                    )
                                }

                                <Divider
                                    sx={{ bgcolor: "secondary.light" }}
                                    orientation="vertical"
                                    flexItem />

                                <Link
                                    class="pl-[20px] pr-[30px] py-1 text-md font-semibold rounded-full text-center z-30 w-30 hover:text-slate-700 text-slate-500"
                                    style={{ textDecoration: 'none' }}>
                                    <Dropdown innerContent={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <AiFillApple />
                                            <p>iOS</p>
                                        </Stack>
                                    } />
                                </Link>

                                <motion.div
                                    start={-20}
                                    animate={{ x: xOffset }}
                                    transition={{ ease: "easeInOut", duration: 0.28 }}
                                    class="absolute bottom-[5px] h-4/5 w-[95px] bg-[#F2F2F2] rounded-full">
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    <ProfileAvatar></ProfileAvatar>
                </div>    
    );
}