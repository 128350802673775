import http from "../http-common";

export class LoginService {
    public async sendLoginVerificationEmail(email: string): Promise<any> {
        const response = await http.post("/user/login", { 
            user: { email: email }
        });

        return response.data;
    }
}