
import React, { useState, useMemo, useEffect } from "react";
import Helmet from "react-helmet";
import { UserModel, UpdateNameError, UserService } from "../../services/UserService";
import { Card, Grid, Stack } from "@mui/material";
import HomeRow from "../dashboard/HomeRow";


const FullHeightCard = (props: any) => (
    <Card
        variant="outlined"
        sx={{
            borderRadius: 3,
        }}
        style={{ height: '100%' }}
    >
        {props.children}
    </Card >
);

enum AppleAPIKeyUploadViewModel_Errors {

};

declare interface AppleAPIKeyUploadViewModel {
    keyData: string | null;
    issuerID: string | null;
    keyID: string | null;
}

function EditAppleAPIKeySetting(
    user: UserModel,
    service: UserService
): JSX.Element {
    const [appleAPIKeyData, setAppleAPIKeyData] = useState<string | null>(null);

    if (user == null) { return (<p>Loading...</p>); }

    const DragAndDrop = function () {
        if (!user.hasAppleCredentials) {
            return (<div style={{
                width: '150px',
                height: '150px',
                borderRadius: '12px',
                border: '3px dashed #ccc',
                color: appleAPIKeyData === null ? '#ccc' : 'black',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                justifyContent: 'center'
            }}
            onDragOver={((ev) => { ev.preventDefault(); })}
            onDrop={(ev) => {
                ev.preventDefault();
                const fd = ev.dataTransfer.files.item(0) ;
                var data = ev.dataTransfer.getData('text/plain');
                setAppleAPIKeyData(data);
            }}>
                { appleAPIKeyData === null ? 'Drag & Drop API Key' : 'Ready To Upload...'}
            </div>);
        } else {
            return (<></>);
        }
    }

    const FormFields = function () {
        if (!user.hasAppleCredentials) {
            return (<form onSubmit={(e) => {
                e.preventDefault();
                console.log('submit now!');
            }}>
                <input
                    type='text'
                    placeholder='Key ID'/>
                <input
                    type='text'
                    placeholder="Issuer ID"/>
                <input type='submit'></input>
            </form>);
        } else {
            return (<button onClick={ (_) => {
                    if (window.confirm('Are you sure you want to delete your existing Apple API Credentials?')) {
                        service.SetAppleAPIKey(null);
                    }
                }}>
                    Delete Apple API Credentials
                </button>);
        }
    }

    const Title = function () {
        if (user.hasAppleCredentials) {
            return (<strong><p>✔️ You have provided your Apple API Key</p></strong>)
        } else {
            return (<strong><p>❌ Connect Your Apple Account</p></strong>);
        }
    };

    return (
        <FullHeightCard>
        {Title()}
        {DragAndDrop()}
        {FormFields()}
        </FullHeightCard>
    );
}

function DisplayNameSetting(
    isEditingDisplayName: boolean, setIsEditingDisplayName: ((newBool: boolean) => void),
    user: UserModel, setUser: ((newUser: UserModel) => void),
    service: UserService)
{
    const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
    const [updateName, setUpdateName] = useState(user?.displayName);
    const [errorSettingName, setErrorSettingName] = useState<UpdateNameError | null>(null);

    if (isEditingDisplayName) {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                setIsWaitingForNetwork(true);
                service.UpdateName(updateName).then((result) =>{
                    setIsWaitingForNetwork(false);
                    if (typeof(result) === 'object') {
                        setUser(result);
                    } else {
                        setErrorSettingName(result);
                    }
                    setIsEditingDisplayName(false);
                });
            }}>
                <input disabled={isWaitingForNetwork}
                    type='text'
                    onChange={(e) => { setUpdateName(e.target.value); }}
                    value={updateName}
                    defaultValue={user?.displayName}
                ></input>
            </form>
        );
    } else {
        return (
            <p style={{ fontWeight: 'bold' }}>
                {user?.displayName ?? "Loading..."}
            </p>
        );
    }
}

export default function ProfileSettingsPage() {
    const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
    const [user, setUser] = useState<UserModel | null>(null);
    const service = useMemo(() => { return new UserService() }, []);

    if (user === null) {
        service.GetUser().then((u) => {
            setUser(u);
        });
    }

    useEffect(() => {
        service.AddUserUpdateHandler('ProfileSettings', (u) => setUser(u));
        return () => { service.RemoveUserUpdateHandler('ProfileSettings') };
    }, [service]);

    return (
        <span>
            <Helmet>
                <style>{"body { background-color : #F7F7F9; }"}</style>
            </Helmet>

            <HomeRow/>
            <div style={{height: '100px'}}></div>

            <Stack
                alignContent={'center'}
                alignItems={'center'}
                spacing={2}
            >
                <p id='settings-profile-icon' style={{
                    width: '150px',
                    height: '150px',
                    opacity: '0.5',
                    filter: 'blur(3px)'
                }}
                dangerouslySetInnerHTML={{ __html: user?.displayIconInnerHTML ?? '' }}>
                </p>
                
                <Stack
                    alignItems={'center'}
                    spacing={1} direction={'row'}>
                    <svg 
                        onClick={(_) => setIsEditingDisplayName(!isEditingDisplayName)}
                        width="20" height="20" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M55.4289 11.0642C56.8489 9.37188 59.3719 9.15114 61.0642 10.5712L76.3851 23.4269C78.0774 24.8469 78.2981 27.3699 76.8781 29.0622L45.8543 66.035C45.3369 66.6516 44.6455 67.0977 43.8705 67.3151L26.7978 72.1043C24.0864 72.865 21.4588 70.6601 21.737 67.8578L23.4888 50.2129C23.5683 49.4119 23.8877 48.6535 24.4051 48.0369L55.4289 11.0642Z"
                              fill="#0d6efd"/>
                    </svg>
                    {DisplayNameSetting(isEditingDisplayName, setIsEditingDisplayName, user, setUser, service)}
                </Stack>
            </Stack>

            <div className="col-start-0 col-span-4 py-10 z-0">
                <Grid>
                    <Grid item xs={3}>
                        {EditAppleAPIKeySetting(user, service)}
                    </Grid>
                </Grid>
            </div>
        </span>
    );
}